import { yupResolver } from '@hookform/resolvers/yup';
import SubmitButton from 'components/Form/SubmitButton';
import React, { ChangeEvent, createRef, FormEvent, ReactElement, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import fr from 'react-phone-input-2/lang/fr.json';
import 'react-phone-input-2/lib/style.css';
import { useMutation } from 'react-query';
import Recaptcha from 'react-recaptcha';
import { createRequestContact } from 'services/customer';
import { Gdpr, MessageInput, PhoneContainer, TextInput } from './styled';
import schema from './validation';

const Form = (): ReactElement => {
    const defaultValues: RequestContactValues = {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        subject: '',
        message: '',
        gdpr: false,
    };
    const { t, i18n } = useTranslation('form');
    const [submitted, setSubmitted] = useState(false);
    const [country, setCountry] = useState<string>(i18n.language || 'fr');
    const formMethods = useForm<RequestContactValues>({
        defaultValues,
        resolver: (data, context, options) => yupResolver(schema)(data, { country }, options),
    });

    const reCaptchaInstance = createRef<Recaptcha>();
    const formRef = useRef<HTMLFormElement>(null);

    const mutation = useMutation(['request_contact'], (data: RequestContactPostData) => createRequestContact(data));

    const onSubmit = (data: RequestContactPostData): void => {
        setSubmitted(false);
        mutation
            .mutateAsync(data)
            .then(() => {
                setSubmitted(true);
            })
            .catch(() => {
                setSubmitted(true);
            });
    };

    const executeCaptcha = (event: FormEvent): void => {
        event.preventDefault();
        setSubmitted(false);
        reCaptchaInstance.current?.execute();
    };

    const {
        handleSubmit,
        register,
        setValue,
        formState: { errors },
    } = formMethods;

    const { name } = register('phoneNumber');

    return (
        <div className="w-100">
            {submitted && (
                <div className="alert alert-success contact_success" role="alert">
                    <Trans t={t}>form:contact.status.sent</Trans>
                </div>
            )}

            <FormProvider {...formMethods}>
                <form className="pt-1" ref={formRef}>
                    <div className="has-validation my-2">
                        <TextInput
                            placeholder={`${t('form:contact.first_name')} *`}
                            {...register('firstName')}
                            className={errors.firstName ? 'is-invalid' : ''}
                        />
                        {errors.firstName && (
                            <div className="invalid-feedback">
                                <Trans t={t}>{errors.firstName.message}</Trans>
                            </div>
                        )}
                    </div>
                    <div className="has-validation my-2">
                        <TextInput
                            placeholder={`${t('form:contact.last_name')} *`}
                            {...register('lastName')}
                            className={errors.lastName ? 'is-invalid' : ''}
                        />
                        {errors.lastName && (
                            <div className="invalid-feedback">
                                <Trans t={t}>{errors.lastName.message}</Trans>
                            </div>
                        )}
                    </div>
                    <div className="has-validation my-2">
                        <TextInput
                            placeholder={`${t('form:contact.email')} *`}
                            {...register('email')}
                            className={errors.email ? 'is-invalid' : ''}
                        />
                        {errors.email && (
                            <div className="invalid-feedback">
                                <Trans t={t}>{errors.email.message}</Trans>
                            </div>
                        )}
                    </div>
                    <div className="has-validation my-2">
                        <PhoneContainer className={errors.phoneNumber ? 'is-invalid' : ''}>
                            <PhoneInput
                                localization={i18n.language === 'fr' ? fr : undefined}
                                country={i18n.language === 'fr' ? 'fr' : 'us'}
                                placeholder={`${t('form:contact.phone')} *`}
                                inputProps={{
                                    className: errors.phoneNumber ? 'form-control is-invalid' : 'form-control',
                                    name,
                                }}
                                onChange={(_value, country: { countryCode: string }, _e, formattedValue): void => {
                                    setCountry(country.countryCode);
                                    setValue('phoneNumber', formattedValue);
                                }}
                            />
                        </PhoneContainer>
                        {errors.phoneNumber && (
                            <div className="invalid-feedback">
                                <Trans t={t}>{errors.phoneNumber.message}</Trans>
                            </div>
                        )}
                    </div>

                    <div className="has-validation my-2">
                        <TextInput
                            placeholder={`${t('form:contact.subject')} *`}
                            {...register('subject')}
                            className={errors.subject ? 'is-invalid' : ''}
                        />
                        {errors.subject && (
                            <div className="invalid-feedback">
                                <Trans t={t}>{errors.subject.message}</Trans>
                            </div>
                        )}
                    </div>
                    <div className="has-validation my-2">
                        <MessageInput
                            placeholder={`${t('form:contact.message')} *`}
                            {...register('message')}
                            className={errors.message ? 'is-invalid' : ''}
                        />
                        {errors.message && (
                            <div className="invalid-feedback">
                                <Trans t={t}>{errors.message.message}</Trans>
                            </div>
                        )}
                    </div>
                    <div className="has-validation my-2">
                        <Gdpr
                            label={<Trans t={t} i18nKey="form:contact.gdpr" />}
                            {...register('gdpr')}
                            className={errors.gdpr ? 'is-invalid' : ''}
                            onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                                setValue('gdpr', event?.target?.checked || false)
                            }
                        />
                        {errors.gdpr && (
                            <div className="invalid-feedback">
                                <Trans t={t}>{errors.gdpr.message}</Trans>
                            </div>
                        )}
                    </div>
                    <Recaptcha
                        sitekey="6LcQKZsUAAAAAFiEBmr9tn8JT5gNKZEMacZrNH8f"
                        size="invisible"
                        verifyCallback={(response): void => {
                            setSubmitted(false);
                            handleSubmit((data) =>
                                onSubmit({
                                    ...data,
                                    locale: i18n.language === 'fr' ? '/locales/1' : '/locales/2',
                                    gRecaptchaToken: response,
                                }),
                            )();
                        }}
                        ref={reCaptchaInstance}
                        hl={i18n.language}
                    />

                    <SubmitButton className="text-uppercase w-100" onClick={executeCaptcha} id="contact_submit">
                        <Trans t={t}>form:contact.submit</Trans>
                    </SubmitButton>
                </form>
            </FormProvider>
        </div>
    );
};

export default Form;
