import * as yup from 'yup';
import 'yup-phone';

const schema = yup.object().shape({
    firstName: yup.string().required('form:errors.required'),
    lastName: yup.string().required('form:errors.required'),
    email: yup.string().email('form:errors.email').required('form:errors.required'),
    phoneNumber: yup
        .string()
        .required('form:errors.required')
        .when('$country', (country, schema) => schema.phone(country, true, 'form:errors.phone_number')),
    subject: yup.string().required('form:errors.required'),
    message: yup.string().required('form:errors.required'),
    gdpr: yup.boolean().isTrue('form:errors.gdpr').required('form:errors.required'),
});

export default schema;
