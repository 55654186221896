import useSSR from 'hooks/useSSR';
import React, { ReactElement } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import H1 from 'components/Layout/H1';
import GreyContainer from 'components/Layout/GreyContainer';
import Form from 'components/Contact/Form';
import { FormCol, MapCol, Team } from './styled';
import map from 'images/static/contact/stbarth_map.png';

const Contact = (): ReactElement => {
    const { t, i18n } = useTranslation('static');
    const { browser } = useSSR();

    return (
        <React.Fragment>
            <Helmet>
                <meta
                    property="og:url"
                    content={`${process.env.REACT_APP_SITE_URL}${i18n.language === 'fr' ? '/contact' : '/contact-us'}`}
                />
                <meta property="og:site_name" content={t('static:contact.meta.og.siteName')} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={t('static:contact.meta.og.title')} />
                <meta property="og:description" content={t('static:contact.meta.og.description')} />
                <meta
                    property="og:image"
                    content={`${process.env.REACT_APP_SITE_URL}images/static/discover/beach.png`}
                />
                <meta property="twitter:title" content={t('static:contact.meta.twitter.title')} />
                <meta property="twitter:description" content={t('static:contact.meta.twitter.description')} />
                <meta
                    property="twitter:image"
                    content={`${process.env.REACT_APP_SITE_URL}images/static/discover/beach.png`}
                />
                <meta name="twitter:card" content="summary" />
                <meta property="title" content={t('static:contact.meta.title')} />
                <title>{t('static:contact.meta.title')}</title>
                <meta property="description" content={t('static:contact.meta.description')} />
            </Helmet>
            <Container className="text-center g-3">
                <Col xs={12}>
                    <H1>
                        <Trans t={t}>static:contact.title</Trans>
                    </H1>
                </Col>
                <Col xs={12}>
                    <p>
                        <Trans t={t}>static:contact.p1</Trans>
                    </p>
                    <p>
                        <Trans t={t}>static:contact.p2</Trans>
                    </p>
                    <p>
                        <Trans t={t}>static:contact.p3</Trans>
                    </p>
                    <p>
                        <Trans t={t}>static:contact.p4</Trans>
                    </p>
                    <p className="text-uppercase">
                        <Trans t={t}>static:contact.p5</Trans>
                    </p>
                    <Team>
                        <Trans t={t}>static:contact.team</Trans>
                    </Team>
                </Col>
            </Container>
            <GreyContainer className="flex-grow-1">
                <Container className="g-3">
                    <Row className="py-3 py-xl-5">
                        <FormCol>{browser && <Form />}</FormCol>
                        <MapCol>
                            <div className="mt-3 pt-3 text-center">
                                <h5>IDEAL Villa Rentals</h5>
                                <hr />
                                <Row className="justify-content-center">
                                    <Col xs={12}>
                                        Galerie Commerciale Jean Greaux BP 411 Gustavia - 97133 Saint Barthélemy
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} xl={6}>
                                        <h6>Tel / WhatsApp</h6>
                                        <strong>+ 590 (0) 690 47 02 52</strong>
                                    </Col>
                                    <Col xs={12} xl={6}>
                                        <h6>Toll free from US &amp; Canada</h6>
                                        <strong>+1 (718) 594 1376</strong>
                                        <h6>Office</h6>
                                        <strong>+ 590 (0) 590 52 27 66</strong>
                                    </Col>
                                    <Col xs={12} className="pt-5">
                                        <strong className="contact-mail">reservations@idealstbarth.com</strong>
                                    </Col>
                                    <Col xs={12}>
                                        <Image fluid className="w-100" src={map} />
                                    </Col>
                                </Row>
                            </div>
                        </MapCol>
                    </Row>
                </Container>
            </GreyContainer>
        </React.Fragment>
    );
};

export default Contact;
