import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import gustavia from 'images/static/contact/gustavia_port.png';
import around from 'images/around-title.svg';

const Team = styled.h5.attrs(() => ({ className: 'm-4' }))`
    color: ${(props): string => props.theme.ivr.colors.golden['100']};
    font-size: 1.1rem;
    font-family: ${(props): string => props.theme.ivr.fonts.playfair};
    font-style: italic;
`;

const FormCol = styled(Col).attrs(() => ({
    xs: 12,
    xl: 5,
    className: 'mb-3 mb-xl-0 align-items-center mx-auto bg-white',
}))`
    border: 2px solid ${(props): string => props.theme.ivr.colors.golden['100']};
    padding: 20px;
`;

const MapCol = styled(Col).attrs(() => ({
    xs: 12,
    xl: 5,
    className: 'align-items-center mx-auto bg-white text-center pb-3',
}))`
    background-image: url(${gustavia});
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    font-size: 0.8rem;

    & > div {
        background-image: linear-gradient(0deg, hsla(0, 0%, 100%, 0.25), #fff);
    }

    hr {
        background-color: ${(props): string => props.theme.ivr.colors.golden['100']};
        height: 0.02rem;
        margin: 1rem auto;
        opacity: 1;
        width: 85%;
    }

    h5 {
        color: ${(props): string => props.theme.ivr.colors.black['100']};
        font-family: ${(props): string => props.theme.ivr.fonts.playfair};
        font-style: italic;
        font-size: 1.1rem;
    }

    h6 {
        color: ${(props): string => props.theme.ivr.colors.golden['100']};
        font-size: 1.1rem;
    }

    .contact-mail {
        position: relative;

        &:before {
            background-image: url(${around});
            content: '';
            height: 22px;
            left: 50%;
            position: absolute;
            top: -20px;
            transform: translateX(-50%) translateY(-50%);
            width: 33px;
        }
    }
`;

export { FormCol, MapCol, Team };
